import * as React from "react";
import type { HeadFC } from "gatsby";
import { MainLayout } from "../components/layouts/main-layout";
import { Container, Divider, Heading, Text } from "@chakra-ui/react";
import AddressLine from "../components/address-line";

const IndexPage = () => {
  return (
    <MainLayout>
      <Container>
        <Heading>Adresa</Heading>
        <AddressLine />
        <Divider my={30} />
        <Heading>Kontakty</Heading>
        <Text>+421 911 219 911</Text>
        <Text>office@incubegarage.com</Text>
        <Divider my={30} />
        INCUBE Garage s.r.o. , Hlavná 10/14 , 040 01 Košice 1 , IČO: 54094828 ,
        IČ-DPH: SK2121566007 , iban: SK36 1100 0000 0029 4513 6942
      </Container>
    </MainLayout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Home Page</title>;
